import http from "@/api/http.js";

function listAll() {
  return http({
    url: "/PmsMeadjohnson/List",
    method: "get",
  });
}

export default {
  listAll,
};
