const pagination = {
  current: 1,
  defaultCurrent: 1,
  showLessItems: true,
  showSizeChanger: true,
  pageSize: 0,
  defaultPageSize: 10,
  pageSizeOptions: ["10", "50", "100", "500"],
  showTotal: (total, range) =>
    `当前显示第${range[0]}-${range[1]}条，共${total}条`,
  showSizeChange: (current) => console.log(current),
};

export default pagination;
