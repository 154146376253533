const filtration = {
  filterInput: "",
  status: false,
  dataSource: [],
  filterResult: [],
  fuzzyResult: (source, input) => {
    if (!source || source.length === 0) return [];
    if (!input) return source;
    return source.filter((item) => {
      for (const key in item) {
        return item[key] && item[key].toString().indexOf(input.trim()) !== -1;
      }
    });
  },
};

export default filtration;
