<template>
  <div class="pms-meadjohnson">
    <div class="header">
      <a-breadcrumb style="margin-bottom: 20px">
        <a-breadcrumb-item>平台管理</a-breadcrumb-item>
        <a-breadcrumb-item>美赞臣信息</a-breadcrumb-item>
      </a-breadcrumb>
      <a-row>
        <a-col :span="18"> </a-col>
        <a-col :span="6">
          <a-input-search
            v-model="dataSourceSearchInput"
            placeholder="请输入任何想搜索的内容"
            allowClear
            @search="handleDataSourceSearch"
          />
        </a-col>
      </a-row>
    </div>
    <div class="body">
      <a-table
        rowKey="id"
        :data-source="getCurrentDataSourceList"
        :pagination="paginationOptions"
        @change="
          (pagination) => {
            paginationOptions.current = pagination.current;
            paginationOptions.pageSize = pagination.pageSize;
          }
        "
        :scroll="{ x: 1200 }"
        bordered
        size="small"
      >
        <a-table-column key="index" title="序号" :width="40" data-index="index">
          <template slot-scope="text, record, index">
            <span>{{
              (paginationOptions.current - 1) * paginationOptions.pageSize +
              parseInt(index) +
              1
            }}</span>
          </template>
        </a-table-column>
        <a-table-column key="id" title="ID" :width="200" data-index="id" />
        <a-table-column
          key="name"
          title="名称"
          :width="200"
          data-index="name"
        />
        <a-table-column
          key="baseUrl"
          title="接口地址"
          :width="200"
          data-index="baseUrl"
        />
        <a-table-column
          key="publicKey"
          title="公钥"
          :width="100"
          data-index="publicKey"
        >
          <template slot-scope="text">
            <a>查看详情</a>
          </template>
        </a-table-column>
        <a-table-column
          key="privateKey"
          title="私钥"
          :width="100"
          data-index="privateKey"
        >
          <template slot-scope="text">
            <a>查看详情</a>
          </template>
        </a-table-column>
        <a-table-column
          key="token"
          title="Token"
          :width="100"
          data-index="token"
        />
        <a-table-column
          key="status"
          title="状态"
          :width="100"
          data-index="status"
        >
          <template slot-scope="text">
            <a-switch
              checked-children="启用"
              un-checked-children="禁用"
              :checked="text === 1"
            />
          </template>
        </a-table-column>
        <a-table-column
          key="remark"
          title="备注"
          :width="200"
          data-index="remark"
        />
        <a-table-column
          key="createTime"
          title="创建时间"
          :width="200"
          data-index="createTime"
        />
        <a-table-column
          key="createBy"
          title="创建人"
          :width="200"
          data-index="createBy"
        />
      </a-table>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import PaginationOptions from "@/options/pagination.js";
import FiltrationOptions from "@/options/filtration.js";
import PmsMeadjohnsonApi from "@/api/pms/pms-meadjohnson.js";
import { mapState } from "vuex";

export default {
  name: "PmsMeadjohnson",
  data() {
    return {
      paginationOptions: PaginationOptions,
      filtrationOptions: FiltrationOptions,
      queryType: "1",
      queryDate: [],
      dataSourceList: [],
      dataSourceCurrent: {},
      dataSourceSearchInput: "",
      dataSourceSearchList: [],
      dataSourceSearchValid: false,
    };
  },
  computed: {
    ...mapState({}),
    getCurrentDataSourceList: function () {
      return this.dataSourceSearchValid
        ? this.dataSourceSearchList
        : this.dataSourceList;
    },
  },
  watch: {
    dataSourceSearchInput: function (val) {
      if (val === null || val === "") {
        this.dataSourceSearchList = [];
        this.dataSourceSearchValid = false;
      }
    },
  },
  mounted: function () {
    this.fetchMeadjohnsonList();
  },
  methods: {
    fetchMeadjohnsonList: function () {
      PmsMeadjohnsonApi.listAll().then((response) => {
        if (response.status === "SUCCESS") {
          this.dataSourceList = response.body;
          if (this.dataSourceList.length === 0) this.$message.success("无数据");
        } else {
          this.dataSourceList = [];
        }
      });
    },
    handleTableChange(pagination) {
      this.paginationOptions.current = pagination.current;
      this.paginationOptions.pageSize = pagination.pageSize;
    },
    handleDataSourceSearch: function (value) {
      if (value === null || value === "") {
        this.dataSourceSearchList = [];
        this.dataSourceSearchValid = false;
        return;
      }
      this.dataSourceSearchList = this.dataSourceList.filter((item) => {
        return JSON.stringify(item).indexOf(value.trim()) !== -1;
      });
      this.dataSourceSearchValid = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 20px;
}
</style>
